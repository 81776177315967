import ReactImageMagnify from 'react-image-magnify';

const KCH011 = () => {
  const coverImage = '/img/albums/liquid-mech.jpg';
  return (
    <section class='content-container'>
      <div class='flex justify-between items-center flex-wrap mx-10 pt-[80px] lg:flex-nowrap'>
        <div class='md:mb-0 hidden lg:block lg:max-w-[500px] '>
          <ReactImageMagnify
            {...{
              smallImage: {
                alt: 'Strange Passenger',
                isFluidWidth: true,
                src: coverImage,
              },
              largeImage: {
                src: coverImage,
                width: 1200,
                height: 1200,
              },
            }}
          />
        </div>
        <div class='mb-4 lg:hidden '>
          <img src={coverImage} alt='Strange Passenger' />
        </div>

        <div class='lg:w-[70%] lg:pl-[209px] md:pb-4 z-50'>
          <h2 class='font-medium pt-2'>Liquid Mechanics</h2>
          <p>Strange Passenger [KCH011]</p>
          <p class='text-justify py-5 lg:mr-[150px]'>
            With this minimalistic and deep release in the style of
            Minimal/Deeptech, Kirill Simonia sets the night on fire with his
            captivating beats and hypnotic rhythms. "Strange Passenger" features
            two original tracks that take you on a sonic journey and also
            includes two remixes. “Strange Passenger” remix by Cantheloop , who
            delivers a deep and intricate reimagining, and label co-owner Eugene
            Schieffer, who presents a dynamic and dancefloor-oriented rework of
            the track "Compulsion."
          </p>
          <div className='bandcamp-player'>
            <iframe
              title='ttt'
              class='w-full border-0'
              height={120}
              src='https://bandcamp.com/EmbeddedPlayer/album=1105145655/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/'
              seamless
            >
              <a href='https://kocherecords.bandcamp.com/album/strange-passenger'>
                Strange Passenger by Liquid Mechanics, Cantheloop, Eugene
                Schieffer
              </a>
            </iframe>
          </div>
          <div class='py-4 lg:pt-[70px]'>
            <div>
              Listen on{' '}
              <a
                href='https://soundcloud.com/kocherecords/sets/liquid-mechanics-strange-passenger'
                class='hover-underline-animation'
              >
                SoundCloud
              </a>
            </div>
            <div>
              Buy on{' '}
              <a
                href='https://www.beatport.com/release/strange-passenger/4160152'
                class='hover-underline-animation'
              >
                Beatport
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default KCH011;
