const Events = () => {
  return (
    <section>
      <div class="mx-10 h-screen lg:h-full pt-[80px] lg:pt-0">
        <p>Events will be announced soon.</p>
      </div>
    </section>
  );
};

export default Events;
