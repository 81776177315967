export const mixes = [
  {
    name: 'Thomas Carmody',
    desc: '039',
    image: '/img/podcasts/39.jpeg',
    href: 'https://soundcloud.com/kocherecords/koche-podcast-039-thomas-carmody',
  },
  {
    name: 'Lexia Majora',
    desc: '038',
    image: '/img/podcasts/38.jpeg',
    href: 'https://soundcloud.com/kocherecords/koche-podcast-38-lexia-majora',
  },
  {
    name: 'Mad Vasco',
    desc: '037',
    image: '/img/podcasts/37.jpeg',
    href: 'https://soundcloud.com/kocherecords/koche-podcast-mad-vasco',
  },
  {
    name: 'Fardin Ameri b2b Morteza Minouei',
    desc: '036',
    image: '/img/podcasts/36.jpeg',
    href: 'https://soundcloud.com/kocherecords/cd-only-djset-vol002-fardin-ameri-b2b-morteza-minouei',
  },
  {
    name: 'Liquid Mechanics',
    desc: '035',
    image: '/img/podcasts/35.png',
    href: 'https://soundcloud.com/kocherecords/koche-podcast-035-liquid-mechanics',
  },
  {
    name: 'Gisli',
    desc: '034',
    image: '/img/podcasts/34.png',
    href: 'https://soundcloud.com/kocherecords/koche-podcast-034-gisli',
  },
  {
    name: 'SMK',
    desc: '033',
    image: '/img/podcasts/33.png',
    href: 'https://soundcloud.com/kocherecords/koche-podcast-033-smk',
  },
  {
    name: 'Katharine',
    desc: '032',
    image: '/img/podcasts/32.png',
    href: 'https://soundcloud.com/kocherecords/koche-podcast-032-katharine',
  },
  {
    name: 'Joshua Arocha',
    desc: '031',
    image: '/img/podcasts/31.jpg',
    href: 'https://soundcloud.com/kocherecords/koche-podcast-031-joshua-arocha',
  },
  {
    name: 'Protect',
    desc: '030',
    image: '/img/podcasts/030.jpg',
    href: 'https://soundcloud.com/kocherecords/koche-podcast-030-protect',
  },
  {
    name: 'Jacques Le Flamand',
    desc: '029',
    image: '/img/podcasts/029.jpg',
    href: 'https://soundcloud.com/kocherecords/koche-podcast-029-jacques-le-flamand',
  },
  {
    name: 'Sophilia',
    desc: '028',
    image: '/img/podcasts/028.jpg',
    href: 'https://soundcloud.com/kocherecords/koche-podcast-028-sophilia',
  },
  {
    name: 'Volar',
    desc: '027',
    image: '/img/podcasts/027.jpg',
    href: 'https://soundcloud.com/kocherecords/koche-podcast-027-volar',
  },
  {
    name: 'Timbol',
    desc: '026 Vinyl Only',
    image: '/img/podcasts/026.png',
    href: 'https://soundcloud.com/kocherecords/koche-podcast-026-timbol-vinyl-only',
  },
  {
    name: 'Microxika',
    desc: '025 Vinyl Only',
    image: '/img/podcasts/025.jpg',
    href: 'https://soundcloud.com/kocherecords/koche-podcast-025-microxika',
  },
  {
    name: 'Zaverescu',
    desc: '024',
    image: '/img/podcasts/024.jpg',
    href: 'https://soundcloud.com/kocherecords/koche-podcast-024-zaverescu',
  },
  {
    name: 'Filin',
    desc: '023 Vinyl Only',
    image: '/img/podcasts/023.jpg',
    href: 'https://soundcloud.com/kocherecords/koche-podcast-023-filin-vinyl-only',
  },
  {
    name: 'Hari',
    desc: '022 Vinyl Only',
    image: '/img/podcasts/022.jpg',
    href: 'https://soundcloud.com/kocherecords/koche-podcast-022-hari-vinyl-only',
  },
  {
    name: 'Basti Lorenz',
    desc: '021',
    image: '/img/podcasts/021.jpg',
    href: 'https://soundcloud.com/kocherecords/koche-podcast-021-basti-lorenz',
  },
  {
    name: 'Play Something',
    desc: '020',
    image: '/img/podcasts/020.jpg',
    href: 'https://soundcloud.com/kocherecords/koche-podcast-020-play-something',
  },
  {
    name: 'sa.mu',
    desc: '019',
    image: '/img/podcasts/019.jpg',
    href: 'https://soundcloud.com/kocherecords/kochepodcast-019-samu',
  },
  {
    name: 'Phil dB+',
    desc: '018 Vinyl Only',
    image: '/img/podcasts/018.jpg',
    href: 'https://soundcloud.com/kocherecords/koche-podcast-018-phil-db',
  },
  {
    name: 'Mario Rösner',
    desc: '017',
    image: '/img/podcasts/017.jpg',
    href: 'https://soundcloud.com/kocherecords/koche-podcast-017-mario-rosner',
  },
  {
    name: 'Moriòn',
    desc: '016 Vinyl Only',
    image: '/img/podcasts/016.jpg',
    href: 'https://soundcloud.com/kocherecords/koche-podcast-016-morion-vinyl-only',
  },
  {
    name: 'Nicolas Barnes',
    desc: '015',
    image: '/img/podcasts/015.jpg',
    href: 'https://soundcloud.com/kocherecords/nicolas-barnes-mix',
  },
  {
    name: 'Matthias Lindner',
    desc: '014',
    image: '/img/podcasts/014.jpg',
    href: 'https://soundcloud.com/kocherecords/koche-podcast-014-matthias-lindner',
  },
  {
    name: 'Dreimal T',
    desc: '013 Vinyl Only',
    image: '/img/podcasts/013.jpg',
    href: 'https://soundcloud.com/kocherecords/koche-podcast-13-dreimal-t-vinyl-only',
  },

  {
    name: 'steeloo',
    desc: '012',
    image: '/img/podcasts/012.jpg',
    href: 'https://soundcloud.com/kocherecords/koche-podcast-12-steeloo',
  },
  {
    name: 'Canteloop',
    desc: '011',
    image: '/img/podcasts/011.jpg',
    href: 'https://soundcloud.com/kocherecords/koche-podcast-11-cantheloop',
  },
  {
    name: 'BART',
    desc: '010',
    image: '/img/podcasts/010.jpg',
    href: 'https://soundcloud.com/kocherecords/koche-podcast-10-bart',
  },
  {
    name: 'Eraseland',
    desc: '009 Own Productions',
    image: '/img/podcasts/009.jpg',
    href: 'https://soundcloud.com/kocherecords/eraseland-own-productions-podcast',
  },
  {
    name: 'Eugene Schieffer',
    desc: '008',
    image: '/img/podcasts/008.jpg',
    href: 'https://soundcloud.com/kocherecords/koche-podcast-08-eugene-schieffer',
  },
  {
    name: 'Rhom Omit',
    desc: '007',
    image: '/img/podcasts/007.jpg',
    href: 'https://soundcloud.com/kocherecords/koche-podcast-07-rhom-omit',
  },
  {
    name: 'Boujay',
    desc: '006',
    image: '/img/podcasts/006.jpg',
    href: 'https://soundcloud.com/kocherecords/koche-podcast-06-khaled-aj',
  },
  {
    name: 'Louis the 4th',
    desc: '005',
    image: '/img/podcasts/005.jpg',
    href: 'https://soundcloud.com/kocherecords/podcast-05-louis-the-4th',
  },
  {
    name: 'Christine Schieffer',
    desc: '004',
    image: '/img/podcasts/004.jpg',
    href: 'https://soundcloud.com/kocherecords/koche-podcast-04-christine-schieffer',
  },
  {
    name: 'Lucitum',
    desc: '003',
    image: '/img/podcasts/003.jpg',
    href: 'https://soundcloud.com/kocherecords/koche-podcast-03-lucitum',
  },
  {
    name: 'Dreimal T',
    desc: '002 Vinyl Only',
    image: '/img/podcasts/002.jpg',
    href: 'https://soundcloud.com/kocherecords/koche-podcast-02-dreimal-t',
  },
  {
    name: 'Play Something',
    desc: '001',
    image: '/img/podcasts/001.jpg',
    href: 'https://soundcloud.com/kocherecords/koche-podcast-01-eugene-schieffer-b2b-christine-schieffer',
  },
];
